import { HTMLAttributes } from 'react';
import { Link } from '@remix-run/react';
import type { RemixNavLinkProps } from '@remix-run/react/dist/components';
import { useLegacyAppLink } from '../../contexts/LegacyAppLinkContext';

interface RoccoLinkProps
    extends HTMLAttributes<HTMLAnchorElement>,
        Pick<RemixNavLinkProps, 'prefetch'> {
    to: string;
    isNonRemixRoute?: boolean;
}

export const RoccoLink = ({
    children,
    to,
    isNonRemixRoute = false,
    prefetch,
    ...props
}: RoccoLinkProps) => {
    const useLegacyLink = useLegacyAppLink();
    if (useLegacyLink || isNonRemixRoute) {
        return (
            <a href={to} {...props}>
                {children}
            </a>
        );
    }

    return (
        <Link to={to} prefetch={prefetch} {...props}>
            {children}
        </Link>
    );
};
