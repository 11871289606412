import { createContext, useContext, PropsWithChildren } from 'react';

/**
 * Context to determine if legacy app links should be used
 */
export const LegacyAppLinkContext = createContext<boolean>(false);

/**
 * Hook to access the legacy app link context
 * @returns boolean indicating if legacy app links should be used
 */
export const useLegacyAppLink = (): boolean => {
    return useContext(LegacyAppLinkContext);
};

/**
 * Provider component for legacy app link context
 */
export const LegacyAppLinkProvider = ({
    useLegacy,
    children,
}: PropsWithChildren<{ useLegacy: boolean }>): JSX.Element => {
    return (
        <LegacyAppLinkContext.Provider value={useLegacy}>
            {children}
        </LegacyAppLinkContext.Provider>
    );
};
